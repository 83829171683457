import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'menuitems.tai_khoan',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'account-info', name: 'menuitems.account_info', type: 'link' }],
};

export const MENU_ITEMS_LUUTRU = {
    state: 'storage',
    name: 'menuitems.luu_tru',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'files-downloaded', name: 'menuitems.file_downloaded', type: 'link' }],
};

export const MENU_ITEMS_TIENICH = {
    state: 'tien-ich-syll',
    name: 'menuitems.tien_ich',
    type: 'sub',
    icon: 'extension',
    children: [
        { state: 'syll-khoa-hoc', name: 'menuitems.tien_ich_syll_khoa_hoc', type: 'link' },
        { state: 'syll-chi-tiet', name: 'menuitems.tien_ich_syll_chi_tiet', type: 'link' },
    ],
};

export const MENU_ITEMS_STAFF = {
    state: 'staff',
    name: 'menuitems.ho_so_ca_nhan',
    type: 'sub',
    icon: 'work',
    children: [
        { state: 'info', name: 'menuitems.ho_so_ca_nhan_cua_toi', type: 'link' },
        { state: 'history', name: 'menuitems.staff_activity_history', type: 'link' },
    ],
};

// export const MENU_ITEMS_DETAI = {
//     state: 'detai',
//     name: 'menuitems.de_tai',
//     type: 'sub',
//     icon: 'book',
//     children: [{ state: 'list', name: 'menuitems.de_tai_cua_toi', type: 'link' }],
// };

export const MENU_ITEMS_NHIEMVU = {
    state: 'nhiemvu',
    name: 'menuitems.nhiem_vu',
    type: 'sub',
    icon: 'app_registration',
    children: [
        { state: 'list', name: 'menuitems.nhiem_vu_cua_toi', type: 'link' },
        { state: 'danh-muc', name: 'menuitems.nhiemvu_danh_muc_list', type: 'link' },
    ],
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.staff, MENU_ITEMS_STAFF],
    // [MCODES.detai, MENU_ITEMS_DETAI],
    [MCODES.nhiemvu, MENU_ITEMS_NHIEMVU],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.staff,
    // MCODES.detai,
    MCODES.nhiemvu,
    MCODES.tienich,
    MCODES.luutru,
    MCODES.taikhoan,
];
