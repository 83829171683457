import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './authentication/keycloak/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: { someRoles: ['admin', 'hids.canbo.staff'] },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },

            // Account
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },

            // Storage
            {
                path: 'storage',
                loadChildren: () => import('./storage/files.module').then((m) => m.StorageModule),
            },

            // Tien ich SYLL
            {
                path: 'tien-ich-syll',
                loadChildren: () => import('./tien-ich-syll/tien-ich-syll.module').then((m) => m.TienIchSyllModule),
            },

            // Staff
            {
                path: 'staff',
                loadChildren: () => import('./staff/staff.module').then((m) => m.StaffModule),
            },

            // // Detai
            // {
            //     path: 'detai',
            //     loadChildren: () => import('./detai/detai.module').then((m) => m.DetaiModule),
            // },

            // NhiemvuModule
            {
                path: 'nhiemvu',
                loadChildren: () => import('./nhiemvu/nhiemvu.module').then((m) => m.NhiemvuModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
    },
];
